import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl';
import { Icon, Spinner } from '@blueprintjs/core';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import ProgressLeftDrawer from './ProgressLeftDrawer';
import ProgressListFilter from './progress-list-filter/ProgressListFilter'
import ProgressContent from './ProgressContent';
import { getSurveyDeploymentsBySchool } from '../../../../store/surveys-deployment/actions';
import './ProgressMonitoring.scss';
import { useDispatch, useSelector } from 'react-redux';
import { Goal, GraphTypeEnum, StrategicPlanPageTabEnum, Survey, SurveyDeployment } from '../../../../types';
import { Link, generatePath, useHistory } from 'react-router-dom';
import { AuthRoutes } from '../../../../App';
import jsPDF from "jspdf";
import "jspdf-autotable";
import { INDICATOR_DOMAIN_LEVEL, INDICATOR_ITEM_LEVEL } from '../../../../constants/constants';
import { clearRespondentGraphDomainsItemsArray, getRespondentGraphDomainData, getRespondentGraphItemData } from '../../../../store/survey-reports/actions';
import moment from 'moment';
import _ from 'lodash';

type OwnProps = {};

type Props = OwnProps;
declare module 'jspdf' {
    interface jsPDF {
      autoTable: (options: any) => jsPDF;
      previousAutoTable?: {
        finalY: number;
      };
    }

  }

const ProgressMonitoring: React.FC<Props> = (props) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const history = useHistory();
    // STATES
    const [isSideMenuClosed, setIsSideMenuClosed] = useState<boolean>(false);
    const [selectedGoal, setSelectedGoal] = useState<any>("");
    const [isGoalChange, setIsGoalChange] = useState<boolean>(false);
    const [selectedGraph, setSelectedGraph] = useState<string>(GraphTypeEnum.RESPONDENT_GROUP_GRAPH);

    const [SurveyIdIndex, setSurveyIdIndex] = useState<number>(0);
     const [ItemsIndex, setItemsIndex] = useState<number>(0);
     const [idsArray, setIdsArray] = useState<any>([]);
     const [respondentDomainsData,setRespondentDomainsData]=useState<any>();
     const [refresh,setRefresh]=useState<any>(false);

     const [selectedDomainArray,setSelectedDomainArray]=useState<any[]>([])
      const [selectedItemArray,setSelectedItemArray]=useState<any[]>([])

    const plan = useSelector((s) => s.plans.activePlan);
    const planId = plan?.school.id;

    useEffect(() => {
      if (planId) {
        dispatch(getSurveyDeploymentsBySchool.request(planId));
      }
    }, [planId, dispatch]);

    const surveyDeployments = useSelector(
      (s) => s.surveysDeployment.surveysDeploymentBySchool
    );
    const surveyDeploymentsBySchool:SurveyDeployment<number, Survey<number>>[] = useSelector(
      (s) => s.surveysDeployment.surveysDeploymentBySchool
    );


    const [selectedDomainIdsFiltered, setSelectedDomainIdsFiltered] = useState<any>();


    const filteredSurveyDeployments= surveyDeployments.filter((surveyDeployment:any)=>surveyDeployment.is_deleted===false && surveyDeployment.state==="ready");

    const surveyDeploymentIds = filteredSurveyDeployments.map((s: any) => s.id); // survey deployment ids
    const RespondentDomainsData = _.flatMapDeep(useSelector(
      (s) => s.surveyReports.surveyRespondentDomainsData
    ));


    const RespondentGraphDomainsData =  RespondentDomainsData; 
    const RespondentGraphItemsData = 

    useSelector(
        (s) => s.surveyReports.surveyRespondentItemsData
      );
      const RespondentGraphDomainsDataLoading = useSelector(
        (s) => s.surveyReports.loading.getRespondentGraphDomainData
      );
      const RespondentGraphItemsDataLoading = useSelector(
        (s) => s.surveyReports.loading.getRespondentGraphItemData
      );
    
    // Graph options
    const graphOptions = useMemo(() => {
        return Object.values(GraphTypeEnum).map((r, i) => ({
            label: r,
            value: i,
            translate: intl.formatMessage({ id: `app.progress-monitoring.graph.${r}` }),
        }));
    }, [intl]);
    // USE SELECTOR
    const { goals } = useSelector((s) => s.goals);

    const { goal } = useSelector((s) => s.goals.newGoalState);

    const completedGoals: any[] = useMemo(() => {
        let goalList: Goal[] = [...goals];
        return goalList?.filter((goal: Goal) => (goal && !!goal?.selected_domains && goal?.targets?.length > 0 && !!goal.desired_outcome && !!goal.statement && !goal?.targets?.find((et: any) => (!et.target_date || !et.target_value))))
    }, [goals]);
    

    const selectedDomains=selectedGoal?.selected_domains
    const selectedTarget=selectedGoal?.targets


    useEffect(() => {
        setSelectedGoal(completedGoals[0]);
    }, [completedGoals]);
   

    const selectedDateRange = useMemo(() => {
        const dateArray = surveyDeploymentsBySchool.map((srd: any) => srd.start_date);
        const dateObjects: any = dateArray.map((dateStr) => new Date(dateStr));
  
        // Find the lowest and greatest dates
        const lowestDate = new Date(Math.min(...dateObjects));
        const greatestDate = new Date(Math.max(...dateObjects));
  
        // Create groups
        const startYear = lowestDate.getFullYear();
        const endYear = greatestDate.getFullYear();
  
        const startDate = new Date(startYear, 6, 1); // july 1, year
        const endDate = new Date(endYear, 5, 30); // june 30, year
  
        const datesArray: string[] = [];
        let currentDate = startDate;
  
        while (currentDate <= endDate) {
          const formattedDate = currentDate.toLocaleDateString("en-US", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          });
  
          datesArray.push(moment(formattedDate).format("MM/DD/YY"));
          currentDate.setDate(currentDate.getDate() + 1); // Move to the next day
        }
  
        return datesArray;
      }, [surveyDeploymentsBySchool]);
  
  
    const selectedDomainDemographic=(domainName:string,demographic:string)=>{
     if(selectedDomainArray.find((obj) => obj.domain === domainName && obj.demographic===demographic)){
       selectedDomainArray.splice(selectedDomainArray.findIndex((obj) => obj.domain === domainName && obj.demographic===demographic),1)
     }else{
      selectedDomainArray.push({domain:domainName,demographic:demographic})
     }
    }
  
  
    const selectedDomainItemDemographic:any=(domainName:string,demographic:string,text:string,leadIn:string)=>{
  
      if(selectedItemArray.find((obj) => obj.domain === domainName && obj.demographic===demographic && obj.text===text)){
        selectedItemArray.splice(selectedItemArray.findIndex((obj) => obj.domain === domainName && obj.demographic===demographic && obj.text === text),1)
      }else{
        selectedItemArray.push({domain:domainName,demographic:demographic,text:text,leadIn:leadIn})
      } 
     }
  
     const groupedItems:any = {};
  
    useEffect(() => {
  
       selectedDomains?.map((d: any) => {
          getDomainItemsOption(d?.name)
          groupedValuesArray(d?.name)
        //   {Object.keys(groupedValuesArray(d?.name)).map(
        //     (item: any) => (
        //     groupedItems[d.name]?.forEach((it:any) => {
  
        //           selectedDomainItemDemographic(d?.name,item,it[item]?.text,it[item]?.leadIn) 
  
        //     })
  
        //  ))}
          //setFilteredItem(groupedItems);
          //console.log('groupedItems ' , JSON.stringify(groupedItems))
      });
  
    }, [selectedDomains]);
    const handleGoalSelect = (goal: React.SetStateAction<Goal | undefined>) => {
      if(selectedGoal !== goal){
        setIsGoalChange(!isGoalChange)
        setSelectedGoal(goal);
       setSelectedDomainArray([])
        setSelectedItemArray([])
      }
  
    };
  
    useEffect(() => {
  
     (selectedItemArray?.length===0 || selectedDomainArray?.length === 0 )&& selectedTarget?.map((tar: any) => {
             tar?.type==="domain_level" && selectedDomainDemographic(tar?.domain_priority?.domain?.name,tar?.demographic)
             tar?.type==="item_level" && selectedDomainItemDemographic(tar?.item_priority?.domain?.name,
              tar?.item_priority?.demographic, 
              tar?.item_priority?.item?.text,
              tar?.item_priority?.item?.lead_in
              ) 
            })
  
  
    }, [selectedTarget,selectedDomains,selectedGoal]);
  
  
    const getDomainItemsOption=(name:string)=>{
      selectedGoal?.targets?.filter((tar:any)=>tar?.type === "item_level"
      && tar?.item_priority?.domain?.name === name ).forEach((it:any) => {
      const key = `${it.item_priority.domain.name}`; // Create a unique key using domain name 
  
          if (!groupedItems[key]) {
            groupedItems[key] = []; // Initialize an empty array for each unique key
          }
  // Push the item text to the corresponding key array
      groupedItems[key].push({[it.demographic]:{text:it.item_priority?.item?.text,leadIn:it.item_priority?.item?.lead_in}});
  })        
  
    }
  
  
  
    const groupedValuesArray=(d:any)=>{
      const groupedValues:any = [];
      groupedItems[d]?.forEach((item:any) => {
      const key = Object.keys(item)[0]; // Get the key of each object
      if (!groupedValues[key]) {
        groupedValues[key] = []; // Initialize an empty array for each unique key
      } 
     groupedValues[key].push(item[key]);
    })
    return groupedValues
    }  
    

    const handleGraphChange = (event: SelectChangeEvent) => {
        setSelectedGraph(event.target.value);
    };

    const canNavigate = () => {
        history.push(
            generatePath(AuthRoutes.YourPlan, {
                workspace: "goals" as any,
                tabId: "planning"
            } as any)
        );
    }
   
    
   useEffect(() => {
   
        if (selectedGoal) {
            const selectedDomainIds = selectedGoal?.selected_domains
            ? selectedGoal.selected_domains.map((sd: any) => sd.id)
            : (console.error('selected_domains is undefined'), null);
            setSelectedDomainIdsFiltered(selectedDomainIds)
        }
      }, [selectedGoal]);


   
   

      useEffect(() => {
        if (selectedGoal) {
          dispatch(clearRespondentGraphDomainsItemsArray([]));
            const idArray:any=[];
            surveyDeploymentIds?.forEach((value: any, index: number) => {
            selectedDomainIdsFiltered?.forEach((domain_id: any, ind: number) => {
                idArray.push({ surveyDeploymentID: value, domain: domain_id });
            });
        });
        setIdsArray(idArray)
    }
      }, [selectedDomainIdsFiltered, selectedGoal, surveyDeployments]);

      useEffect(() => {
        
       
        if (SurveyIdIndex === 0 && refresh ===true) {
          
             dispatch(clearRespondentGraphDomainsItemsArray([]));
           }
            if(refresh ===true ){
               dispatch(
               getRespondentGraphDomainData.request({
                 survey_deployment_id: surveyDeploymentIds,
                 school_id: [selectedGoal?.plans?.school?.id],
                 // hide_sensitive_reporting_data:localFlagToHideSensitiveData
               })
             );
            
         
         setRespondentDomainsData(RespondentGraphDomainsData)
        }
       
   }, [refresh]);



      useEffect(() => {
        
        if(refresh ===true && idsArray){ 
           dispatch(
            getRespondentGraphItemData.request({
              survey_deployment_id: idsArray,
              school_id: [selectedGoal?.plans?.school?.id],
              // domain_id: [idsArray[ItemsIndex]?.domain_id],
            })
          );
          }
      }, [refresh]);
     

       const getDomainMean = (d: any,demographic:any) => {
    const Data: any = [];

      RespondentGraphDomainsData?.map((rg: any) => {
        return rg?.domain?.name === d &&
          rg?.respondents[`${demographic}`] 
          
          ? Data.push({
              deployment_name: rg?.survey_deployment[0]?.deployment_name,
              MeanScore: Number(rg?.respondents[
                demographic
              ].mean_score).toFixed(2)==="0.00"? intl.formatMessage({id:"app.non-ideal-state.survey-reports.not-enough-respondents"}):Number(rg?.respondents[
                demographic
              ].mean_score).toFixed(2),
            })
          : "";
      })


    return Data;
  };

  const getItemMean = (text: string, domain: any,demographic:any) => {
    const Data: any = [];

    try{
      if( RespondentGraphItemsData && RespondentGraphItemsData.length > 0 && RespondentGraphItemsData[0].length > 0 ) {

        RespondentGraphItemsData[0]?.map((fsc: any) =>
          fsc?.domain?.name === domain &&
          fsc?.responses?.forEach((response: any) => {
              if (
                fsc?.domain?.name === domain &&
                response.respondents[`${demographic}`] &&
                response.respondents[
                  `${demographic}`
                ]?.question_text?.includes(text) 
                
              ) {
                return Data.push({
                  ItemMeanScore: Number(response?.respondents?.[
                    `${demographic}`
                  ]?.mean_score)?.toFixed(2)==="0.00"?intl.formatMessage({id:"app.non-ideal-state.survey-reports.not-enough-respondents"}):Number(response?.respondents?.[
                    `${demographic}`
                  ]?.mean_score)?.toFixed(2),
                  deployment_name: response?.survey_deployment[0]?.deployment_name,
                });
              } else {
                return false;
              }
            })
          )
      }
    } catch(error){
      console.log(error, 'errorOnAdminEmptyPage');
    }

    return Data;
  };
    
      const TableData = useMemo(() => {
        let domainItemData: any = [];
    
    
    
    if (selectedDomainArray?.length) {
      selectedDomainArray?.map((domain: any) => {
        const ItemsData: any = [];
        selectedItemArray?.map((surveyItem: any) => {
          const ItemMean=getItemMean(surveyItem.text, domain.domain === surveyItem.domain && surveyItem.domain, domain.demographic === surveyItem.demographic && surveyItem.demographic)
          const ItemTargetScore =selectedGoal?.targets?.map((target: any) =>
          target?.item_priority?.item?.text.includes(surveyItem.text)
            ? target.target_value
            : ""
        );
          ItemMean?.length>0 && ItemsData.push({
            ItemText: surveyItem.text,
            leadIn: surveyItem.leadIn,
            ItemMeanScore: ItemMean, 
            TargetScore: ItemTargetScore           
          });
        });
        
        const domainTargetScore = selectedGoal ? selectedGoal?.targets?.map((target: any) =>
        target?.domain_priority?.demographic === domain.demographic &&
        target?.domain_priority?.domain?.name === domain.domain
          ? target.target_value
          : ""
      ) :""

        domainItemData.push({
          DomainRespondentGroup: [intl.formatMessage({
            id: `app.filters.respondent-type.${domain.demographic}`,
          })],
          DomainMeanScore: getDomainMean(domain.domain,domain.demographic),
          DomainName: domain.domain,
          ItemData: ItemsData,
          type: "domain_level",
          DomainTargetScore: domainTargetScore,
        });
      });
    }
    return domainItemData;
  }, [
    selectedDomainArray,
    selectedItemArray,
    RespondentGraphItemsData,
    RespondentGraphDomainsData
  ]);



  const groupedData:any = [];

  TableData?.forEach((item:any) => {
    const domainName = item.DomainName;
  
    if (!groupedData[domainName]) {
      groupedData[domainName] = []; // Initialize an empty array for each unique domain name
    }
  
    // Push the item to the corresponding domain name array
    groupedData[domainName]=({...groupedData[domainName],[item?.DomainRespondentGroup]:item});
  });


      const exportAsTable = () => {
        const surveyData: any[] = TableData;
      
        const data =surveyData.map((svd:any)=>svd?.DomainName)
        const seenNames = new Set();
        
        const removeDuplicateDomainNames = data.map(name => {
          if (seenNames.has(name)) {
            return "";
          } else {
            seenNames.add(name);
            return name;
          }
        });

        const pdf = new jsPDF('l', 'pt', "a4");
        
        pdf.setFontSize(16);
        pdf.setFont('Helvetica-Bold', '4');
        pdf.text(intl.formatMessage({id:"app.titles.njsci.progress.data.report.survey"}), pdf.internal.pageSize.getWidth() / 2, 15, { align: 'center' });

        pdf.setFont('Helvetica-Bold', '4');
        pdf.text(selectedGoal?.plans?.school?.name, pdf.internal.pageSize.getWidth() / 2, 40, { align: 'center' });

        pdf.setFont('Helvetica-Bold', '4');
        pdf.text("Goal: "+selectedGoal?.statement, pdf.internal.pageSize.getWidth() / 2, 70  , { align: 'center' });
      
        let startY:number = 85; // Initialize starting Y position
        
        pdf.setFontSize(12);

        const tableData = Object.keys(groupedData);
        tableData?.map((domain:any, index:number) => {
          if (index === 0) {
            pdf.setFont('Helvetica-Light', '4');
          
        }
        const RespondentsGroups = Object.keys(groupedData[domain]);
        pdf.setFont('Helvetica-Bold', '4');
        pdf.text(domain, 40 , 75, { align: 'left' });
  
        RespondentsGroups.map((rg)=>
        { 
          
          const domainData = groupedData[domain][rg]

        
          var meanScoreTableData:any[]=[];
          if(domainData.DomainMeanScore?.length>0){
          meanScoreTableData = [[intl.formatMessage({id:"app.titles.respondent.group"}), ...domainData.DomainMeanScore?.map((score:any) => `Domain Mean ${score.deployment_name}`), intl.formatMessage({id:"app.titles.target-score"})]];
          if (Array.isArray(domainData.DomainRespondentGroup)) {
          domainData.DomainRespondentGroup?.forEach((respondentGroup:any, rowIndex:any) => {
          const rowData = [respondentGroup];
      
          domainData.DomainMeanScore?.forEach((score:any) => {
            rowData.push(score && score.MeanScore ? score.MeanScore : '');
          });
      
          // Add Target Score at the end
          rowData.push(domainData?.DomainTargetScore ?
            domainData?.DomainTargetScore?.find((ds: any) => ds !== "") !== undefined
              ? Number(domainData.DomainTargetScore.find((ds: any) => ds !== "")).toFixed(2)
              : "":"");
      
          meanScoreTableData.push(rowData);
        });
      } else {
        const respondentGroup = domainData?.DomainRespondentGroup;
        const meanScore1 = domainData?.DomainMeanScore?.length > 0 ? domainData?.DomainMeanScore?.map((dms:any)=>dms.MeanScore) : '';
      
        const targetScore = domainData?.DomainTargetScore ?
        domainData?.DomainTargetScore?.find((ds: any) => ds !== "") !== undefined
          ? Number(domainData.DomainTargetScore.find((ds: any) => ds !== "")).toFixed(2)
          : "":""
      
      meanScoreTableData.push([respondentGroup, ...meanScore1, targetScore]);
      }
    }            
              pdf.autoTable({
                head: meanScoreTableData.slice(0, 1),
                body: meanScoreTableData.slice(1),
                startY: startY,
                margin: { top: 8 },
                tableHeaderStyles: { fillColor: [32, 40, 91], fontStyle: 'bold', textColor: [255, 255, 255] }, // Dark blue background color
                styles: {
                  columnStyles: {
                    0: { fontStyle: 'bold' }, // Make the content in the first column bold
                  }
                },
                columnStyles: {
                  0: { cellWidth: 150 }, // Set the first column width to 150
                },
              });
              if(domainData.ItemData?.length>0){
    
    
              }
    
              startY = pdf.previousAutoTable ? pdf.previousAutoTable?.finalY :65
              const itemDataTableData = [[intl.formatMessage({id:"app.titles.survey-item"}), intl.formatMessage({id:"app.titles.target-score"})]];
    
              // Check if ItemData exists and has at least one item
              if (domainData.ItemData && domainData.ItemData?.length > 0) {
                const firstItem = domainData?.ItemData[0];
              
                // Check if the first item has ItemMeanScore and it's an array with at least one element
                if (firstItem.ItemMeanScore && firstItem.ItemMeanScore?.length > 0) {
                  // Iterate over the ItemMeanScore array and add deployment_name values to the table header
                  firstItem.ItemMeanScore.forEach((itemMeanScore:any, index:any) => {
                    const deploymentName = itemMeanScore.deployment_name;
                    itemDataTableData[0].splice(index + 1, 0, `Item Mean ${deploymentName}`);
                  });
                }
              
                // Add additional rows for each survey item in ItemData
                domainData.ItemData?.forEach((item:any) => {
                  const rowData = [item.leadIn?item.leadIn+" "+item.ItemText:item.ItemText];
              
                  // Check if ItemMeanScore exists and it's an array with at least one element
                  if (item.ItemMeanScore && item.ItemMeanScore?.length > 0) {
                    // Add mean scores to the row
                    item.ItemMeanScore?.forEach((itemMeanScore:any) => {
                      rowData.push(itemMeanScore.ItemMeanScore || '');
                    });
                  }
                  
                  const ItemTargetScore = selectedGoal && Number(selectedGoal?.targets?.map((target: any) =>
                  target?.item_priority?.item?.text.includes(item.ItemText)
                    ? target.target_value
                    : ""
                ).find((ds: any) => ds !== "") ).toFixed(2)
    
                  // Add Target Score at the end
                  rowData.push(item.ItemText ? ItemTargetScore : '');
              
                  // Add the row to itemDataTableData
                  itemDataTableData.push(rowData);
                });
              }
              
            
             if(domainData?.ItemData?.length){
               pdf.autoTable({
                head: itemDataTableData.slice(0, 1),
                body: itemDataTableData.slice(1),
                startY: startY, // Start right after the previous table
                margin: { top: 6 }, // Set top margin to 0
                headStyles: { fillColor: [128,128,128] },
                columnStyles: {
                  0: { cellWidth: 150 }, // Set the first column width to 150
                },
              })
            }
    
              startY = pdf.previousAutoTable ? pdf.previousAutoTable.finalY + 10:10;
        })
     
        

          // Add a new page for each domain, except the last one
          if (tableData[index] !== tableData[index+1] && tableData[index+1] !==undefined) {
            pdf.addPage();
            startY=85;
          }
        });
        
        
        // Save the PDF
        pdf.save('survey_results.pdf');
                 
      };

useEffect(()=>{
  if(RespondentGraphItemsDataLoading ===false && RespondentGraphDomainsDataLoading ===false && refresh === true){
    exportAsTable(); // generate and download PDF 
    setRefresh(false);
  }
},[RespondentGraphDomainsDataLoading,RespondentGraphItemsDataLoading]);
    return (
        <div className="progressM-wrapper">
            {
                !completedGoals?.length ? (
                    <div className="notfound-message no-stragies-found">
                        <div className="notfound-message_inner">
                            <h3>{intl.formatMessage({ id: "app.titles.no-goals-progress-monitor" }, {
                                br: <br />,
                                b: (chunks) => <b>{chunks}</b>,
                                i: (chunks) => <i>{chunks}</i>,
                            })}</h3>
                            <p>
                                {intl.formatMessage({ id: "app.titles.no-goals-progress-monitor-statement" },
                                    {
                                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                        a: (part) => <a onClick={canNavigate} className="underline text-blue-500">{part}</a>,
                                    }
                                )}
                            </p>
                        </div>
                    </div>)
                    : (
                        <div className={`planning ${(isSideMenuClosed ? 'collapse-toggle' : '')}`}>
                            {/* left menu show for complete goals */}
                            <ProgressLeftDrawer
                                isSideMenuClosed={isSideMenuClosed}
                                setIsSideMenuClosed={setIsSideMenuClosed}
                                completedGoals={completedGoals}
                                onSelect={handleGoalSelect}
                                selectedGoal={selectedGoal}
                            />

                            <div className="planning__midd">
                                <div className="monitoringMid-topBar">
                                    <div className="">
                                        <h2>{selectedGoal?.statement}</h2>
                                    </div>
                                    {/* button for redirect on change score calcu;ator */}
                                    <ul>
                                    {(RespondentGraphDomainsDataLoading || RespondentGraphItemsDataLoading || surveyDeployments.length === 0 || refresh ) ? <Spinner intent='primary' size={30} /> : 
                                       <li>
                                            {/* <button className="linkBtn"><Icon iconSize={16} icon="calculator" />{intl.formatMessage({ id: "app.progress-monitoring.change-score-calculator" })}</button> */}
                                           
                                           <button className="linkBtn" onClick={()=>setRefresh(true)} ><Icon iconSize={16} icon="import" /> &nbsp; {intl.formatMessage({ id: "app.progress-monitoring.survey_report" })}</button>
                                           
                                        </li>
                                        }
                                           
                                        
                                    </ul>
                                </div>
                                
                                {/* for Progress list filter */}
                                <ProgressListFilter graphType={selectedGraph} selectedGoal={selectedGoal} isGoalChange={isGoalChange}/>
                                
                            </div>
                        </div>
                    )
            }
        </div>
    )
}

export default ProgressMonitoring;