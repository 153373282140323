import React, { FunctionComponent, useState, useMemo, useEffect } from "react";
import { Icon, InputGroup } from "@blueprintjs/core";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { UserNotes } from "../../../../types";
import {
  getActivePlanId,
  isDistrictPlan,
} from "../../../../store/plans/selectors";
import NotesListItem from "./NotesListItem";
import { PUSHER_KEY, PUSHER_CLUSTER } from "../../../../api/config";
import Pusher from "pusher-js";
import { triggerPusherEvent } from "../../../../api/notes";
import {
  getNotes,
  pusherDeleteNoteEvent,
  pusherNewNoteEvent,
  pusherUpdateNoteEvent,
  saveNoteToRedux1,
  showNotesDialog,
} from "../../../../store/notes/actions";
import events from "../../../pages/strategic-plan/events";
// import { UserNotes, UserNotesType } from '../../../../../../../types';
// import NoteCardPublishedNote from '../../../../../../team-plan/notes/notes-dialog/NoteCardPublishedNote';
// import { getActivePlanId, isDistrictPlan } from '../../../../../../../store/plans/selectors';

type OwnProps = {
  isSideMenuClosed: boolean;
  setIsSideMenuClosed: any;
  pusher?: any;
  filterByKeyword: any;
  filterByTopic: any;
  selectedDateRange: any;
  isFilterApplied: Boolean;
};

type Props = OwnProps;

const NotesList: FunctionComponent<Props> = ({
  isSideMenuClosed,
  setIsSideMenuClosed,
  pusher,
  filterByKeyword,
  filterByTopic,
  selectedDateRange,
  isFilterApplied,
}) => {
  // CONSTANTS
  const [] = useState("");
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [selectedNote, setSelectedNote] = useState<UserNotes | undefined>();
  const notes = useSelector((s) => s.notes.notes);
  const seledctedNoteRedux = useSelector(
    (s) => s.notes.dialogs.notesDialog.selectedNote
  );
  const loading = useSelector((s) => s.notes.loading.getNotes);
  const error = useSelector((s) => s.notes.errors.getNotes);
  const [searchString, setSearchString] = useState<string>("");
  const [beingEdited, setBeingEdited] = useState<boolean>(false);
  const intl = useIntl();
  const dispatch = useDispatch();
  const activePlanId = useSelector(getActivePlanId);

  const isDistrictActivePlan = useSelector(isDistrictPlan);

  const NOTES_GENERAL_CHANNEL = useMemo(() => {
    return "private-notes-list-" + activePlanId; //"private-notes-list-" + activePlanId; // "presence-notes-list-"
  }, [activePlanId]);

  const filteredNotes = useMemo(() => {
    let filtered = notes.sort(
      (a, b) => moment(b.updated_at).unix() - moment(a.updated_at).unix()
    );
// Function to convert MM/DD/YY to a full date string (YYYY-MM-DD) for accurate comparison
function convertToFullDate(dateStr:string) {
  const [month, day, year] = dateStr.split('/').map(Number);
  const fullYear = year < 100 ? (year + 2000) : year;
  return `${fullYear}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
}


    // Filter by date range
    if (selectedDateRange) {
      const [startDateStr, endDateStr] = selectedDateRange.split(" - ");
     
      const startDate = moment(new Date(convertToFullDate(startDateStr))).format("MM/DD/yy");
      const endDate = moment(new Date(convertToFullDate(endDateStr))).format("MM/DD/yy");

      filtered = filtered.filter((note) => {
        const createdAt = moment(new Date(note.updated_at)).format("MM/DD/yy");
        return createdAt >= startDate && createdAt <= endDate;
    });
    }

    // Filter by keyword
    if (filterByKeyword) {
      const lowerCaseKeyword = filterByKeyword.toLowerCase();
      filtered = filtered.filter((note) => {
        const creationDate = moment(note.created_at);
        let name = note.user?.first_name + " " + note.user?.last_name;
        let re = new RegExp(`${lowerCaseKeyword}`, "g");
        return (
          note.note_title?.toLowerCase()?.includes(lowerCaseKeyword) ||
          note.text?.toLowerCase()?.includes(lowerCaseKeyword) ||
          name?.toLowerCase()?.match(re) ||
          note.note_type?.toLowerCase()?.includes(lowerCaseKeyword) ||
          moment(note.created_at)
            .format("M/D/yy hh:mm A")
            .includes(lowerCaseKeyword) ||
          creationDate
            .format("dddd")
            .toLowerCase()
            .includes(lowerCaseKeyword) || // e.g. Wednesday
          creationDate
            .format("MMMM")
            .toLowerCase()
            .includes(lowerCaseKeyword) || // e.g. November
          creationDate.format("DD").includes(lowerCaseKeyword) || // e.g. 24
          creationDate.format("MM").includes(lowerCaseKeyword) || // e.g. 11
          creationDate.format("YYYY").includes(lowerCaseKeyword)
        );
      });
    }

    // Filter by topic
    if (filterByTopic) {
      const lowerCaseTopic = filterByTopic.toLowerCase();
      filtered = filtered.filter((note) => {
        return note.note_type?.toLowerCase() === lowerCaseTopic; // Adjust 'note_type' based on your data structure
      });
    }

    return filtered;
  }, [notes, isFilterApplied]);

  const handleToggleSideMenu = () => {
    setIsSideMenuClosed(!isSideMenuClosed);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const onNoteClick = (note?: UserNotes) => {
    setSelectedNote(note);
  };

  useEffect(() => {
    if (notes?.length) {
      setSelectedNote(notes[0]);
      dispatch(saveNoteToRedux1({ selectedNote: notes[0] }));
    } else {
      setSelectedNote(undefined);
      dispatch(saveNoteToRedux1({ selectedNote: undefined }));
    }
  }, [notes]);

  useEffect(() => {
    try {
      let PLAN_NOTES_PRESENCE_CHANNEL = "presence-sci_notes" + activePlanId;
      // "presence-plan-" + activePlanId + "-notes";
      const planPresenceChannel = pusher.subscribe(PLAN_NOTES_PRESENCE_CHANNEL);
      planPresenceChannel.bind(events.note_update, (data: any) => {
        // update note in redux
        dispatch(pusherUpdateNoteEvent(data?.data?.note));
        // if (seledctedNoteRedux?.id == data?.data?.note?.id)
        //   dispatch(saveNoteToRedux1({ selectedNote: {...seledctedNoteRedux,...data?.data?.note} }));
      });
      planPresenceChannel.bind(events.note_create, (data: any) => {
        // add note in redux
        // if calendar note get all the meeting AGENDA
        // api call
        if (activePlanId) dispatch(getNotes.request({ planId: activePlanId }));
        // dispatch(pusherNewNoteEvent(data?.data?.note))
      });
      planPresenceChannel.bind(events.note_delete, (data: any) => {
        // remove note from redux
        dispatch(pusherDeleteNoteEvent(data?.data));
      });
      return () => {
        planPresenceChannel.unbind(); // Unbind event listeners
        planPresenceChannel.unsubscribe(); // Unsubscribe channel
      };
    } catch (err) {
      console.log(err);
    }
    // }
  }, [activePlanId]);

  return (
    <div className={`planning__left `}>
      <div className="left-inner">
        <div
          className="left-bar-toggle"
          id="planning-toggle"
          onClick={() => handleToggleSideMenu()}
        >
          {isSideMenuClosed ? (
            <Icon icon="chevron-right" iconSize={16} />
          ) : (
            <Icon icon="chevron-left" iconSize={16} />
          )}
        </div>

        {/* <div className="meetings">(3) Meeting Agendas</div> */}
        <div className="planning__left-links">
          <ul>
            {/* <li className="readableContent">
                            <a href="#">Develop Team and Plan to Communicate about the SCCP</a>
                        </li>
                        <li className="readableContent">
                            <a href="#">Plan for Survey Administrtaion</a>
                        </li>
                        <li className="readableContent">
                            <a href="#">Develop Team and Plan to Communicate about SCCP</a>
                        </li>
                        <li className="readableContent">
                    
                            <a href="#">Develop Team and Plan to Communicate about the SCCP</a>
                            <div className="additionalInfo">
                                <p>Last Edited By: </p>
                                <strong>John Doe</strong> | 20/04/2022 11:00 AM
                            </div>
                        </li>
                        <li className="readableContent">
                 
                      
                            <a href="#">Develop Team and Plan to Communicate about the SCCP</a>
                            <div className="additionalInfo">
                                <p>Last Edited By: </p>
                                <strong>John Doe</strong> | 20/04/2022 11:00 AM
                            </div>
                        </li>
  */}

            <div
              className="notes-container"
              style={{ maxHeight: "500px", overflowY: "auto" }}
            >
              {filteredNotes?.map((note) => (
                <NotesListItem
                  key={note.id}
                  note={note}
                  onClick={onNoteClick}
                  isActive={selectedNote?.id === note.id}
                  activityName={note?.activity?.name}
                />
              ))}
            </div>
          </ul>
          <div className="agendaCalender">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
            >
              <path
                d="M17.019 2.50149H14.7781V0.828491H13.6577V2.50149H4.69405V0.828491H3.5736V2.50149H1.33269C1.18413 2.50149 1.04157 2.56025 0.936527 2.66481C0.831486 2.76937 0.772461 2.91128 0.772461 3.05916V17.0008C0.772461 17.1487 0.831486 17.2906 0.936527 17.3952C1.04157 17.4997 1.18413 17.5585 1.33269 17.5585H17.019C17.1676 17.5585 17.3102 17.4997 17.4152 17.3952C17.5203 17.2906 17.5793 17.1487 17.5793 17.0008V3.05916C17.5793 2.91128 17.5203 2.76937 17.4152 2.66481C17.3102 2.56025 17.1676 2.50149 17.019 2.50149ZM3.5736 3.61682V4.73216H4.69405V3.61682H13.6577V4.73216H14.7781V3.61682H16.4588V5.84749H1.89292V3.61682H3.5736ZM1.89292 16.4432V6.96282H16.4588V16.4432H1.89292Z"
                fill="#155B98"
              />
              <path
                d="M3.29395 8.91467H5.53485V10.03H3.29395V8.91467Z"
                fill="#155B98"
              />
              <path
                d="M6.46973 8.91467H8.71064V10.03H6.46973V8.91467Z"
                fill="#155B98"
              />
              <path
                d="M9.64062 8.91467H11.8815V10.03H9.64062V8.91467Z"
                fill="#155B98"
              />
              <path
                d="M12.8184 8.91467H15.0593V10.03H12.8184V8.91467Z"
                fill="#155B98"
              />
              <path
                d="M3.29395 11.1453H5.53485V12.2606H3.29395V11.1453Z"
                fill="#155B98"
              />
              <path
                d="M6.46973 11.1453H8.71064V12.2606H6.46973V11.1453Z"
                fill="#155B98"
              />
              <path
                d="M9.64062 11.1453H11.8815V12.2606H9.64062V11.1453Z"
                fill="#155B98"
              />
              <path
                d="M12.8184 11.1453H15.0593V12.2606H12.8184V11.1453Z"
                fill="#155B98"
              />
              <path
                d="M3.29395 13.376H5.53485V14.4914H3.29395V13.376Z"
                fill="#155B98"
              />
              <path
                d="M6.46973 13.376H8.71064V14.4914H6.46973V13.376Z"
                fill="#155B98"
              />
              <path
                d="M9.64062 13.376H11.8815V14.4914H9.64062V13.376Z"
                fill="#155B98"
              />
              <path
                d="M12.8184 13.376H15.0593V14.4914H12.8184V13.376Z"
                fill="#155B98"
              />
            </svg>
            <a href="#">
              {intl.formatMessage({ id: "app.titles.go-to-sci-calender" })}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotesList;
