import { Icon, Tabs, Tab, InputGroup, Tooltip, Button } from '@blueprintjs/core';
import { useIntl } from 'react-intl';
import './StrategicPlan.scss';
import TopBar from '../../common/top-bar/top-bar';
import GoalPlanning from './GoalPlanning/GoalPlanning';
import AddGoal from './AddGoal/AddGoal';
import { useGetGoals } from '../../../helpers/hooks/loading/useGetGoals';
import { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EDIT_GOAL_MODE } from '../../../constants/constants';
import { Activity, ActivityTagEnum, ActivityTypeEnum, NewGoalTabs, StrategicPlanPageTabEnum, UserNotesType } from '../../../types';
import { pusherDeleteGoalEvent, pusherNewGoalEvent, pusherUpdateGoalEvent, updateNewGoalState } from '../../../store/goals/actions';
import InfoBar from './InfoBar/InfoBar';
import { generatePath, useHistory, useParams } from 'react-router';
import { AuthRoutes } from '../../../App';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import AddStrategyButton from './strategies/add-strategy/add-strategy-button/AddStrategyButton';
import Strategies from './strategies/Strategies';
import Pusher from 'pusher-js';
import { PUSHER_CLUSTER, PUSHER_KEY } from '../../../api/config';
import events from './events';
// import GoalsPage from '../../team-plan/goals/GoalsPage';
import ProgressMonitoring from './progress-monitoring/ProgressMonitoring';
import StrategicDisclaimer from './strategic-disclaimer/StrategicDisclaimer';
import ScreencastTutorialDialog from '../../team-plan/needs-and-goals/ScreencastTutorialDialog';
import { ACTIVITY_LINK_CLASSNAME, useAttachActivities } from '../../../helpers/hooks/useAttachActivities';
import { showActivityVideoDialog } from '../../../store/activities/actions';
import ActivityResourcesTab from '../../team-plan/members/tabs/resources-tab/ActivityResourcesTab';
import SciWritingComponents from './strategies/edit-core-components/sci-writing/CreateNotes/SciWritingComponents';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import * as pako from 'pako';

type OwnProps = {
  areGoalsAvailable?: boolean;
};

type Props = OwnProps;

const StrategicPlan: FunctionComponent<Props> = (props) =>{
  // CONSTANTS 
  const dispatch = useDispatch();
  const intl = useIntl();
  const history = useHistory();
  
  const { areGoalsAvailable} = props;

  const { tabId } = useParams<{ tabId?: string }>();

  // USE SELECTOR
  const { goal, latestAddedByPusher } = useSelector(s => s.goals.newGoalState);
  const currentUser = useSelector((s) => s.auth.authenticatedUser);
  // const { goals, newGoalState, loading } = useSelector((s) => s.goals);
  const selectedDistrict = useSelector((s) => s.plans.activePlan?.district);
  const selectedSchool = useSelector((s) => s.plans.activePlan);
  const activePlanId = useSelector((s) => s.plans.activePlan?.id);
  const planStrategies = useSelector((s) => s.planStrategies.planStrategies);
  // CUSTOM HOOKS
  const { goals, loading } = useGetGoals();

  // USE STATE 
  const [activeTabId, setActiveTabId] = useState<StrategicPlanPageTabEnum>(selectedDistrict?.is_dummy_district ? StrategicPlanPageTabEnum.Planning:StrategicPlanPageTabEnum.Progress);

  // USE EFFECT 
  useEffect(() => {
    tabId === StrategicPlanPageTabEnum.Strategies && setActiveTabId(tabId);
  }, [tabId]);

  useEffect(() => {
    tabId === StrategicPlanPageTabEnum.Planning && setActiveTabId(tabId);
  }, [tabId]);

  useEffect(() => {
    if (!goals.length) {
      dispatch(updateNewGoalState({
        show: true, selectedGoal: {}, isEditMode: true, goal: {}
      }));
      return;
    }

    // check if the goal is deleted and not present in goals list anymore
    // let isExists: boolean = false;
    // if ((goal && (goal && Object.keys(goal).length !== 0)) && goals.length)
    //   isExists = goals.find(g => g.id === goal.id) ? true : false;
    // if ((!goal || (goal && Object.keys(goal).length === 0)) && goals.length && !isExists) // 👈 null and undefined check
    if (goals.length) // 👈 check for available goals
    {
      const autoSelectedGoal = { ...goals[0] } ?? undefined;
      if (autoSelectedGoal && !latestAddedByPusher)
        dispatch(updateNewGoalState({
          show: true, selectedGoal: autoSelectedGoal, isEditMode: true, goal: {
            ...autoSelectedGoal, user: autoSelectedGoal?.user?.id, mode: EDIT_GOAL_MODE,
            activeTab: NewGoalTabs.GoalStatement,
          }
        }));
    }
  }, [goals.length])

  const GOAL_GENERAL_CHANNEL = useMemo(() => { return "presence-sp-" + activePlanId; }, [activePlanId]);
  const pusher = useMemo(() => {
    return new Pusher(PUSHER_KEY, {
      cluster: PUSHER_CLUSTER,
      channelAuthorization: {
        endpoint: "/pusher/auth-presence",
        transport: "ajax",
        params: {},
        headers: {},
      },
      userAuthentication: {
        endpoint: "/pusher/auth-presence",
        transport: "ajax",
        headers: {
          "X-CSRF-Token": "some_csrf_token",
        },
      },
    });
  }, [])

  const goalPusherGeneralChannel = useMemo(() => {
    return pusher.subscribe(
      GOAL_GENERAL_CHANNEL
    );
  }, [pusher, GOAL_GENERAL_CHANNEL]);

 const GoalPageLink = useCallback( 
    (part) => (   
      <Link to={StrategicPlanPageTabEnum.Planning}  className="underline text-blue-500">
        {part} 
      </Link>
    ),
    []
  );

  

  const isGreenCheckVisible = (goal: any) => {
    return goal.selected_domains.length > 0 && goal.targets.length > 0 && !!goal.desired_outcome && !!goal.statement && !goal.targets.find((et: any) => (!et.target_date || !et.target_value));
}

  useEffect(() => {

    // listen to add new goal event
    goalPusherGeneralChannel.bind(events.goal_create,
      function (data: any) {
        dispatch(pusherNewGoalEvent({ ...data.data }))
      }
    )
    // listen to delete goal event
    if (goals.length && goal) {
      goalPusherGeneralChannel.bind(events.goal_delete,
        function (data: any) {
          dispatch(pusherDeleteGoalEvent(data.data))
        }
      )
      
      goalPusherGeneralChannel.bind(
        events.goal_update_statement,
        function (data: any) {
          const updatedGoalData = data.data.goal;
          const updatedByUser = data.data.user;
          // update the sections of the goal 
          let dt = {};
          if (goal.id == updatedGoalData.id) {
            dt = { ...goal, ...updatedGoalData, user: updatedByUser };
          }
          else {
            let g = goals.find((gt: any) => gt.id == updatedGoalData.id);
            dt = { ...g, ...updatedGoalData, user: updatedByUser }
          }
          if (updatedByUser && updatedByUser.id !== currentUser?.id) {
            dispatch(pusherUpdateGoalEvent(dt));
          }
        }
      );

      goalPusherGeneralChannel.bind(
        events.goal_update_desired_outcome,
        function (data: any) {
          const updatedGoalData = data.data.goal;
          const updatedByUser = data.data.user;
          // update the sections of the goal 
          let dt = {};
          if (goal.id == updatedGoalData.id) {
            dt = { ...goal, ...updatedGoalData, user: updatedByUser };
          }
          else {
            let g = goals.find((gt: any) => gt.id == updatedGoalData.id);
            dt = { ...g, ...updatedGoalData, user: updatedByUser }
          }
          if (updatedByUser && updatedByUser.id !== currentUser?.id) {
            dispatch(pusherUpdateGoalEvent(dt));
          }
        }
      );

      goalPusherGeneralChannel.bind(
        events.goal_target_create,
        function (data: any) {
          const updatedGoalData = data.data.goal;
          const updatedByUser = data.data.user;
          // update the sections of the goal 
          let dt = {};
          if (goal.id == updatedGoalData.id) {
            let targets = [...(goal?.targets ?? [])];
            targets.push(...updatedGoalData.targets)
            dt = { ...goal, ...updatedGoalData, targets: [...targets], user: updatedByUser };
          }
          else {
            let g = goals.find((gt: any) => gt.id == updatedGoalData.id);
            let targets = [...(g?.targets ?? [])];
            targets.push(...updatedGoalData.targets)
            dt = { ...g, ...updatedGoalData, targets: [...targets], user: updatedByUser }
          }
          if (updatedByUser && updatedByUser.id !== currentUser?.id) {
            dispatch(pusherUpdateGoalEvent(dt));
          }
        }
      );

      goalPusherGeneralChannel.bind(
        events.goal_target_update,
        function (data: any) {
          const compressedDataBase64 = data.data;
          // Convert Base64 string back to Uint8Array
      const compressedData = new Uint8Array(atob(compressedDataBase64).split('').map(char => char.charCodeAt(0)));

        // Decompress the data using pako
        const decompressedData = pako.ungzip(compressedData, { to: 'string' });

      // Convert decompressed JSON string to object
      const jsonData = JSON.parse(decompressedData);
            
          const updatedGoalData = jsonData.goal;
          const updatedByUser = jsonData.user;
          // update the sections of the goal 
          let dt = {};
          if (goal.id == updatedGoalData.id) {
            let targets = [...goal.targets.map((ett: any) => {
              if (ett.id == updatedGoalData.updated_target.id)
                return updatedGoalData.updated_target;
              return ett;
            })];
            dt = { ...goal, targets: [...targets], user: updatedByUser };
          }
          else {
            let g = goals.find((gt: any) => gt.id == updatedGoalData.id);
            let targets = g ? [...g.targets.map((ett: any) => {
              if (ett.id == updatedGoalData.updated_target.id)
                return updatedGoalData.updated_target;
              return ett;
            })] : [];
            dt = { ...g, targets: [...targets], user: updatedByUser }
          }
          if (updatedByUser && updatedByUser.id !== currentUser?.id) {
            dispatch(pusherUpdateGoalEvent(dt));
          }
        }
      );

      goalPusherGeneralChannel.bind(
        events.goal_update_domain_remove,
        function (data: any) {

          if (data.data.remove_type == 'item_remove') {
            const targetToRemove = data.data.targetId
            let goalFound = goal;
            if (goalFound.id == data.data.goalId) {
              let tr: any = goal?.targets?.find((et: any) => et?.id == targetToRemove);
              let latestTargets: any[] = [...goal?.targets?.filter((et: any) => et?.id != targetToRemove)];
              let latestDomains: any[] = [...goal?.selected_domains];
              if (tr && tr.type == "domain_level") {
                let rt: any[] = [...goal?.targets?.filter((et: any) => et.id != targetToRemove &&  et.type == "domain_level" && et?.domain_priority.domain.id == tr.domain_priority.domain.id)];
                if (!rt.length)
                  latestDomains = [...goal?.selected_domains?.filter((et: any) => et?.id != tr.domain_priority.domain.id)];
              }
              dispatch(pusherUpdateGoalEvent({ ...goal, targets: [...latestTargets], selected_domains: [...latestDomains] }))
            }
            else {
              let gl = goals.find((et: any) => et.id == data.data.goalId)
              let tr: any = gl?.targets?.find((et: any) => et?.id == targetToRemove);
              if (gl && gl.selected_domains) {
                let latestTargets: any[] = gl ? [...gl?.targets?.filter((et: any) => et?.id != targetToRemove)] : [];
                let latestDomains: any[] = gl ? [...gl.selected_domains]:[];
                if (tr && tr.type == "domain_level") {
                  let rt: any[] = [...gl?.targets?.filter((et: any) => et.id != targetToRemove && et.type == "domain_level" && et?.domain_priority.domain.id == tr.domain_priority.domain.id)];
                  if (!rt.length)
                    latestDomains = [...gl?.selected_domains?.filter((et: any) => et?.id != tr.domain_priority.domain.id)];
                }
                dispatch(pusherUpdateGoalEvent({ ...gl, targets: [...latestTargets], selected_domains: [...latestDomains]  }))
              }
            }
          }
          else if (data.data.remove_type == 'domain_remove') {
            let goalFound = goal;
            if (goalFound.id == data.data.goal_id) {
              const latestDomains = [...goal?.selected_domains?.filter((et: any) => et?.id != data.data.domain_id)];
              const latestTargets = [...goal?.targets?.filter((et: any) => et?.domain_priority?.domain?.id != data.data.domain_id)];
              dispatch(pusherUpdateGoalEvent({ ...goal, targets: [...latestTargets], selected_domains: [...latestDomains] }))
            }else{
              let gl = goals.find((et: any) => et.id == data.data.goal_id);
              if(gl){
                const latestDomains = gl?.selected_domains ? [...gl?.selected_domains?.filter((et: any) => et?.id != data.data.domain_id)]:[];
                const latestTargets = [...gl?.targets?.filter((et: any) => et?.domain_priority?.domain?.id != data.data.domain_id)];
                dispatch(pusherUpdateGoalEvent({ ...gl, targets: [...latestTargets], selected_domains: [...latestDomains] }))
              }
            }
          }
        }
      );
    }
    return () => {
      goalPusherGeneralChannel.unbind_all();
    }
  }, [goalPusherGeneralChannel, goals.length, goal])



  useEffect(() => {
    return () => {
      // pusher.unsubscribe(GOAL_GENERAL_CHANNEL)
      pusher.disconnect();
    };
  }, [])


  //////////////////////////////// Download Strategic Implementation Data Report //////////////////////////////////

const planStrategiesImplementations = useMemo(() => {
  const allImplementationsWithStrategyNames = planStrategies.reduce(
    (acc: any, strategy: any) => {
      return acc.concat(
        strategy.implementations.map((implementation: any) => ({
          ...implementation,
          strategyName: strategy.strategy.name,
        }))
      );
    },
    []
  );

  const sortedData = [...allImplementationsWithStrategyNames].sort(function (
    a,
    b
  ): any {
    if (a === b || (a.strategyName === b.strategyName && a.date === b.date))
      return 0;
    if (a.strategyName > b.strategyName) return 1;
    if (a.strategyName < b.strategyName) return -1;
    if (b.date > a.date) return 1;
    if (b.date < a.date) return -1;
  });

  return sortedData;
}, [planStrategies]);

const exportAsPdf = () => {
  const doc = new jsPDF();

  doc.setFont("Helvetica-Bold", "16");
  doc.text(intl.formatMessage({id:"app.title.strategy-implementation-report-pdf"}),
    doc.internal.pageSize.getWidth() / 2,
    10,
    { align: "center" }
  );

  doc.text(`${selectedSchool?.school?.name}`,doc.internal.pageSize.getWidth() / 2,20,
    { align: "center" }
  );
  autoTable(doc, {
    head: [["Date", "Strategy Name", "Score"]],
    body: planStrategiesImplementations.map((item) => [
      new Date(item.date).toLocaleDateString(),
      item.strategyName,
      Math.round((item.score || 0) * 100) + "%",
    ]),
    startY: 30,
    margin: { top: 10 },
  });

  doc.save("Strategy Implementation Data Report.pdf");
};


//////////////////////////////// Download Strategic Implementation Data Report //////////////////////////////////

  let addButtonContent: {} | null | undefined;
  let screencastTutorialActive = false;
  let screencastStrategyTutorialActive = false;
  let showStrategyImplementationReportButton: {}|null = null;

    switch (activeTabId) {
    case StrategicPlanPageTabEnum.Planning:
      addButtonContent = <AddGoal pusher={pusher} />;
      screencastTutorialActive = true;
      break;
    case StrategicPlanPageTabEnum.Strategies:
      addButtonContent = <AddStrategyButton />;
      screencastStrategyTutorialActive = true;
      break;
    case StrategicPlanPageTabEnum.Progress:
        showStrategyImplementationReportButton = <button className='linkBtn' onClick={exportAsPdf}> <Icon icon='import' ></Icon> Strategy Implementation Report</button>;
       break;
    default:
      addButtonContent = null;
      break;
  }

  const canNavigate = (newTabId: StrategicPlanPageTabEnum) => {
    history.push(
      generatePath(AuthRoutes.YourPlan, {
        workspace: "goals" as any,
        tabId: newTabId
      } as any)
    );
    setActiveTabId(newTabId);
  }

  
  const { onActivityClick } = useAttachActivities({
    onClick: (activity: Activity) => {
      switch (activity.activity_type) {
        case ActivityTypeEnum.KALTURA_VIDEO:
          dispatch(
            showActivityVideoDialog({
              activity: activity,
              viewable_field: "embed_code",
            })
          );
          break;

        default:
          break;
      }
    },
  });

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage({ id: "app.titles.strategic-plan" })}</title>
      </Helmet>
      <div className="strategic-plan">
        <div className="breadcrumbs">
          <ul>

            <li> <Link
              to={generatePath(AuthRoutes.YourPlan,
                {
                  workspace: "dashboard" as any,
                } as any)}
            >{intl.formatMessage({ id: "app.titles.dashboard" })}
            </Link>
            </li>
            <li><span>/</span></li>
            <li>{intl.formatMessage({ id: "app.titles.strategic-plan" })}</li>
          </ul>
        </div>

        <div className="toolbar">
          <h1>{intl.formatMessage({ id: "app.titles.strategic-plan" })}</h1>
          <ul>
            <li>
              {
                screencastTutorialActive &&
                <button
                  className={`${ACTIVITY_LINK_CLASSNAME} playButton`}
                    onClick={
                      onActivityClick(intl.formatMessage(
                        { id: "app.video.screencast-tutorial-of-goal-setting", }
                      ))
                    }
                  >
                    <span className="playButtonBg">
                    <Icon iconSize={15} icon="play" />
                  </span>
                  {intl.formatMessage({ id: "app.titles.screencast-tutorial" })}
                </button>
              }

              {
                screencastStrategyTutorialActive &&
                <button
                  className={`${ACTIVITY_LINK_CLASSNAME} playButton`}
                    onClick={
                      onActivityClick(intl.formatMessage(
                        { id: "app.video.screencast-tutorial-of-strategy-alignmet", }
                      ))
                    }
                  >
                    <span className="playButtonBg">
                    <Icon iconSize={15} icon="play" />
                  </span>
                  {intl.formatMessage({ id: "app.titles.screencast-tutorial" })}
                </button>
              }

            </li>
            <li>{showStrategyImplementationReportButton}</li>
            <li>
              <InfoBar />
            </li>
            <li>
              {addButtonContent}
            </li>
          </ul>
        </div>

        <div className="disclaimer-top-rigth">
          <StrategicDisclaimer />
        </div>

        <div className="strategic-plan__tab">
          <Tabs selectedTabId={activeTabId} animate={true} key={"vertical"} vertical={false} defaultSelectedTabId={StrategicPlanPageTabEnum.Planning} onChange={(newTabId) => canNavigate(newTabId as StrategicPlanPageTabEnum)}>
            {/* Progress Monitoring tab */}
            <Tab key={StrategicPlanPageTabEnum.Progress} id={StrategicPlanPageTabEnum.Progress} title={intl.formatMessage({ id: "app.titles.progress-monitoring" })} hidden={selectedDistrict?.is_dummy_district} panel={
              <ProgressMonitoring />
            } />
            {/* Goal tab */}
            <Tab key={StrategicPlanPageTabEnum.Planning} id={StrategicPlanPageTabEnum.Planning} title={intl.formatMessage({ id: "app.titles.goals" })} panel={
              <div className="strategic-plan__tab-content">
                <GoalPlanning pusher={pusher} goalPusherGeneralChannel={goalPusherGeneralChannel} areGoalsAvailable={goals.length ? true : false} loading={loading} />
              </div>
            } />
            {/* Strategies tab */}
            <Tab key={StrategicPlanPageTabEnum.Strategies} id={StrategicPlanPageTabEnum.Strategies} title={intl.formatMessage({ id: "app.titles.strategies" })} panel={
              <div className="strategic-plan__tab-content">
                <Strategies />
              </div>
            } />

             {/* Sci writing tab */}
             {/* <Tab key={StrategicPlanPageTabEnum.Progress} id={StrategicPlanPageTabEnum.Progress} title={intl.formatMessage({ id: "app.titles.progress-monitoring" })} hidden={!selectedDistrict?.is_dummy_district} panel={
            
            <SciWritingComponents />
        

          } /> */}
            {/* Coverage Matrix tab */}
            {/* <Tab key={StrategicPlanPageTabEnum.Coverage} id={StrategicPlanPageTabEnum.Coverage} title={intl.formatMessage({ id: "app.tiles.coverage-matrix" })} hidden={!selectedDistrict?.is_dummy_district} panel={
              <p>Sample Content 3</p>
            } /> */}
            {/* Resources tab */}
            <Tab key={StrategicPlanPageTabEnum.Resources} id={StrategicPlanPageTabEnum.Resources} title={intl.formatMessage({ id: "app.titles.resources" })}  panel={
              <ActivityResourcesTab 
                activityTag={ActivityTagEnum.GOALS}
                userNotesType={UserNotesType.DC_REPORTS_RESOURCES}
              />
            } />
          </Tabs>
        </div>

      </div>
    </>
  );
}

export default StrategicPlan;